export const FooterNavItems = [
  {
    title: 'Envio',
    url: '#',
    cName: 'nav-links-footer',
  },
  {
    title: 'Aviso de Privacidad',
    url: '#',
    cName: 'nav-links-footer',
  },
  {
    title: 'Terminos y Condiciones',
    url: '#',
    cName: 'nav-links-footer',
  },
  {
    title: 'Tiendas',
    url: '#',
    cName: 'nav-links-footer',
  },
];
