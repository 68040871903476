import React from 'react';
import styled from 'styled-components';

import NavBar from '@/components/NavBar/NavBar';

const Container = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.3) 100%
    ),
    url(${require('../assets/img/LoginBG/login-bg-1.jpg')});
  background-position: top;
  backdrop-filter: blur(5px);
`;

const RegisterPage = () => (
  <>
    <NavBar />
    <Container>
      <div className="flex flex-wrap min-h-screen w-full content-center justify-center py-10">
        <div className="flex shadow-md w-3/4">
          <div className="flex flex-wrap content-center justify-center rounded-l-md bg-white w-full md:w-1/2 h-auto py-10">
            <div className="w-72">
              <h1 className="text-xl font-semibold">Crea tu cuenta</h1>
              <small className="text-gray-400">
                Bienvendio, Por favor ingresa tus datos
              </small>

              <form className="mt-4">
                <div className="mb-3">
                  <label className="mb-2 block text-xs font-semibold">
                    Nombre
                  </label>
                  <input
                    type="email"
                    placeholder="Nombre"
                    className="block w-full rounded-md border border-gray-300 focus:border-black focus:outline-none focus:ring-1 focus:ring-black py-1 px-1.5 text-gray-500"
                  />
                </div>

                <div className="mb-3">
                  <label className="mb-2 block text-xs font-semibold">
                    Apellido
                  </label>
                  <input
                    type="text"
                    placeholder="Apellido"
                    className="block w-full rounded-md border border-gray-300 focus:border-black focus:outline-none focus:ring-1 focus:ring-black py-1 px-1.5 text-gray-500"
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2 block text-xs font-semibold">
                    Correo
                  </label>
                  <input
                    type="email"
                    placeholder="jhon@gmail.com"
                    className="block w-full rounded-md border border-gray-300 focus:border-black focus:outline-none focus:ring-1 focus:ring-black py-1 px-1.5 text-gray-500"
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2 block text-xs font-semibold">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    placeholder=""
                    className="block w-full rounded-md border border-gray-300 focus:border-black focus:outline-none focus:ring-1 focus:ring-black py-1 px-1.5 text-gray-500"
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2 block text-xs font-semibold">
                    Confirmar Contraseña
                  </label>
                  <input
                    type="password"
                    placeholder=""
                    className="block w-full rounded-md border border-gray-300 focus:border-black focus:outline-none focus:ring-1 focus:ring-black py-1 px-1.5 text-gray-500"
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-2 block text-xs font-semibold">
                    Telefono
                  </label>
                  <input
                    type="tel"
                    placeholder="55-5555-5555"
                    className="block w-full rounded-md border border-gray-300 focus:border-black focus:outline-none focus:ring-1 focus:ring-black py-1 px-1.5 text-gray-500"
                  />
                </div>

                <div className="mb-3 flex flex-wrap justify-center items-center content-center">
                  <input
                    id="remember"
                    type="checkbox"
                    className="mr-1 checked:bg-black"
                  />
                  <label
                    htmlFor="remember"
                    className="text-xs font-semibol underline underline-offset-4"
                  >
                    <a href="#">* Aceptar Terminos y Condiciones</a>
                  </label>
                </div>

                <div className="mb-3">
                  <button className="button-23">Crear Cuenta</button>
                  {/*<button className="flex flex-wrap justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-md">
                        <img
                          className="w-5 mr-2"
                          src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        />
                        Acceder con Google
                      </button>*/}
                </div>
              </form>
            </div>
          </div>
          <div className="flex flex-wrap hidden md:inline-block content-center justify-center rounded-r-md md:w-1/2 h-auto">
            <img
              className="w-full h-full bg-center bg-no-repeat bg-cover rounded-r-md object-cover"
              src={require('../assets/img/Summer2022/LaPerez/5L3A0452.jpg')}
            />
          </div>
        </div>
      </div>
    </Container>
  </>
);

export default RegisterPage;
