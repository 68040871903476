import './sidebar.css';

import {
  AttachMoney,
  BarChart,
  ChatBubbleOutline,
  DynamicFeed,
  LineStyle,
  MailOutline,
  PermIdentity,
  Report,
  Storefront,
  Timeline,
  TrendingUp,
  WorkOutline,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Sidebar = () => (
  <div className="sidebar">
    <div className="sidebarWrapper">
      <div className="sidebarMenu">
        <h3 className="sidebarTitle">Dashboard</h3>
        <ul className="sidebarList">
          <Link to="/admin" className="link">
            <li className="sidebarListItem active">
              <LineStyle className="sidebarIcon" />
              Inicio
            </li>
          </Link>
          <li className="sidebarListItem">
            <Timeline className="sidebarIcon" />
            Analisis
          </li>
          <li className="sidebarListItem">
            <TrendingUp className="sidebarIcon" />
            Ventas
          </li>
        </ul>
      </div>
      <div className="sidebarMenu">
        <h3 className="sidebarTitle">Menu</h3>
        <ul className="sidebarList">
          <Link to="/admin/users" className="link">
            <li className="sidebarListItem">
              <PermIdentity className="sidebarIcon" />
              Usuarios
            </li>
          </Link>
          <Link to="/admin/products" className="link">
            <li className="sidebarListItem">
              <Storefront className="sidebarIcon" />
              Productos
            </li>
          </Link>
          <Link to="/admin/orders" className="link">
            <li className="sidebarListItem">
              <AttachMoney className="sidebarIcon" />
              Ordenes
            </li>
          </Link>
          <li className="sidebarListItem">
            <BarChart className="sidebarIcon" />
            Reportes
          </li>
        </ul>
      </div>
      <div className="sidebarMenu">
        <h3 className="sidebarTitle">Notificaciones</h3>
        <ul className="sidebarList">
          <li className="sidebarListItem">
            <MailOutline className="sidebarIcon" />
            Mail
          </li>
          <li className="sidebarListItem">
            <ChatBubbleOutline className="sidebarIcon" />
            Messages
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Sidebar;
