import './magazineBox.css';

import React, { Component } from 'react';

import MagazinesLogos from '../Images/LogosMagazine';

const Gallery = () => (
  <div className="slider-magazine-logos">
    {MagazinesLogos.map((item, index) => (
      <a key={item.title} href={item.url}>
        <img
          key={index}
          src={item.src}
          alt={item.title}
          className={item.cName}
        />
      </a>
    ))}
  </div>
);

class MagazinesBox extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="magazine-logos">
          <Gallery></Gallery>
        </div>
      </React.Fragment>
    );
  }
}
export default MagazinesBox;
