import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import AnimateLogo from '@/components/AnimateLogo';
import MagazinesBox from '@/components/MagazineBox/MagazineBox';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import VerticalCarousel from '@/components/VerticalCarousel';
import { CollectionWinter } from '@/Data/data';

const Container = styled.section`
  position: relative;
`;

const ContainerStand1 = styled.section`
  position: relative;
  background-color: #000000;

  & * {
    color: white;
  }

  & .media-article {
    height: 130vh;
    width: 70vw;
    max-width: 950px;
    overflow: hidden;
  }

  & .artist-logo-nx {
    position: sticky;
    top: 60%;
  }

  @media (max-width: 600px) {
    & .media-article {
      height: fit-content;
      width: 100vw;
    }
  }
`;

const ContainerStand2 = styled.section`
  position: relative;
  background-color: #f7f4e9;
`;

const ContainerStand3 = styled.section`
  position: relative;
`;

const ContainerStand4 = styled.section`
  position: relative;
  background-color: #ffeeee;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2rem;
`;

const SubTitle = styled.h3`
    text-align:center;
    font-size:1.2rem;
    cursor:pointer;

    &:hover{
    text-decoration: underline;
    }

}

`;

const ImageContainer = styled.div`
  width: 70vw;
  max-width: 950px;
  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const Image = styled.img`
  heigth: 100%;
  width: 100%;
  object-fit: cover;
`;

const FirmContainer = styled.div`
  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
    line-height: 1rem;
    padding-left: 10rem;
    cursor: default;
  }

  & h3:hover {
    text-decoration: none;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
`;

const VideoContainer = styled.div``;

const FrontPage = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        // console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        <video
          src={require('../assets/img/Video/Vacios.mp4')}
          type="video/mp4"
          ref={videoEl}
          autoPlay
          controls
          preLoad="auto"
          loop
          muted
          width="100%"
        />

        <LogoContainer>
          <AnimateLogo color="white"></AnimateLogo>
        </LogoContainer>
      </Container>
      <ContainerStand1
        id="highligth-stand-1"
        className="flex flex-col justify-center items-center gap-5 py-10"
      >
        <div className="title-article flex flex-col justify-center gap-2">
          <Title>Primavara Coleccion </Title>
          <SubTitle>Par ella </SubTitle>
        </div>
        <ImageContainer className="media-article">
          <Image
            src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_29.jpg')}
            alt="img"
          />
        </ImageContainer>
        <FirmContainer className="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
      </ContainerStand1>

      <ContainerStand2
        id="highligth-stand-2"
        className="flex flex-col justify-center items-center gap-5 py-64"
      >
        <div className="title-article flex flex-col justify-center gap-4">
          <Title>Primavera 2022 Pre-colección</Title>
          <SubTitle>Para ella </SubTitle>
        </div>
        <VerticalCarousel photos={CollectionWinter}></VerticalCarousel>
        <FirmContainer id="artist-logo-nx">
          <Title>Najjat Harb</Title>
          <SubTitle>& Xavier Berg</SubTitle>
        </FirmContainer>
      </ContainerStand2>
      <ContainerStand3
        id="highligth-stand-3"
        className="flex flex-col justify-center items-center gap-5 py-14"
      >
        <div className="title-article flex flex-col justify-center gap-4">
          <Title>Primavera 2022 Pre-colección</Title>
          <SubTitle>Para ella </SubTitle>
        </div>

        <ImageContainer className="media-article flex justify-end items-end">
          <Image
            src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_50.jpg')}
            alt="img"
          />
        </ImageContainer>
        <FirmContainer id="artist-logo-np">
          <Title>Najjat Harb</Title>
          <SubTitle>& LaPérez</SubTitle>
        </FirmContainer>
      </ContainerStand3>

      <ContainerStand4
        id="highligth-stand-4"
        className="flex flex-col justify-center items-center gap-5 py-20"
      >
        <div className="title-article flex flex-col justify-center gap-4">
          <Title>Primavera 2022 Pre-colección</Title>
          <SubTitle>Para ella </SubTitle>
        </div>

        <ImageContainer className="media-article flex justify-end items-end">
          <Image
            src={require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg')}
            alt="img"
          />
        </ImageContainer>
        <FirmContainer id="artist-logo-np">
          <Title>Najjat Harb</Title>
          <SubTitle>& LaPérez</SubTitle>
        </FirmContainer>
      </ContainerStand4>

      <Newsletter></Newsletter>
      <MagazinesBox></MagazinesBox>
    </>
  );
};

export default FrontPage;
