const cName = '';

export const Magazines = [
  {
    id: 1,
    info: {
      title: 'magazine-portrait-1',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-1.jpg'),
    cName,
  },
  {
    id: 2,
    info: {
      title: 'magazine-portrait-2',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-2.jpg'),
    cName,
  },
  {
    id: 3,
    info: {
      title: 'magazine-portrait-3',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-3.png'),
    cName,
  },
  {
    id: 4,
    info: {
      title: 'magazine-portrait-4',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-4.png'),
    cName,
  },
  {
    id: 5,
    info: {
      title: 'magazine-portrait-5',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-5.png'),
    cName,
  },
  {
    id: 6,
    info: {
      title: 'magazine-portrait-6',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-6.jpg'),
    cName,
  },
  {
    id: 7,
    info: {
      title: 'magazine-portrait-7',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-7.jpg'),
    cName,
  },
  {
    id: 8,
    info: {
      title: 'magazine-portrait-2',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-8.png'),
    cName,
  },
  {
    id: 9,
    info: {
      title: 'magazine-portrait-8',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-9.jpeg'),
    cName,
  },
  {
    id: 10,
    info: {
      title: 'magazine-portrait-9',
      desc: 'Vestido Negro con olanes especiales ',
    },
    url: '#',
    src: require('../assets/img/Magazines/magazine-portrait-10.jpeg'),
    cName,
  },
];
