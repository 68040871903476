import './Footer.css';

import React, { useState } from 'react';

import LogoNajjatWhite from '@/assets/img/icons/Logo-Firma-White.svg';
import SocialLogos from '@/components/Images/SocialLogos';

import { FooterNavItems } from './FooterNavItems';

const Footer = () => (
  <footer className="py-5">
    <div id="najjat-logo-footer">
      <img src={LogoNajjatWhite} alt="Najjat-Logo" className="my-5" />
    </div>
    <div className="primary-footer-nav flex flex-col md:flex-row  justify-center md:justify-between md:items-center md:mx-10 ">
      <ul id="menu-items" className="flex flex-col md:flex-row md:gap-5">
        {FooterNavItems.map((item, index) => (
          <li key={item.title + 'li' + index} className={item.cName}>
            <a key={item.title + index} href={item.url}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="social-box flex flex-end flex-col md:flex-row md:gap-3">
        {SocialLogos.map((item, index) => (
          <a key={item.title} href={item.url}>
            <img
              key={index}
              src={item.src}
              alt={item.title}
              className={item.cName}
            />
          </a>
        ))}
      </div>
    </div>
  </footer>
);

export default Footer;
