import './NavBar.css';

import {
  faBars,
  faClose,
  faShoppingCart,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LogoBlack from '@/assets/img/icons/Logo-Firma.svg';

import BasicMenu from '../Button/BasicMenu';
import { MensMenu, MenuItems, WomanMenu } from './MenuRoutes';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: `white`,
    background: `red`,
  },
}));

const Cart = () => {
  const quantity = useSelector(state => state.cart.quantity);
  return (
    <StyledBadge badgeContent={quantity}>
      <FontAwesomeIcon icon={faShoppingCart} />
    </StyledBadge>
  );
};

const dataSubMenu2 = [
  'LA PEREZ',
  'MIS RAICES',
  'VACIOS',
  'METEPEC-2022',
  'FORESTA',
];

const SubMenus = [MensMenu, WomanMenu];

class NavBar extends Component {
  state = {
    clicked: false,
    fixed: false,
    subMenu: false,
    subMenuCategory: 0,
    imageSrc: require('../../assets/img/Summer2022/LaPerez/5L3A0244.jpg'),
  };

  handleSubMenu = (id, active) => {
    if (id < SubMenus.length) {
      this.setState({ subMenuCategory: id });
      this.setState({ subMenu: active });
    } else {
      this.closeSubMenu();
    }
  };

  closeSubMenu = () => {
    this.setState({ subMenu: false });
  };

  hoverChangeImage = id => {
    this.setState({ imageSrc: id });
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = event => {
    if (window.scrollY === 0 && this.state.fixed === true) {
      this.setState({ fixed: false });
    } else if (window.scrollY !== 0 && this.state.fixed !== true) {
      this.setState({ fixed: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        <header
          className={`${
            this.state.fixed ? 'fixed' : 'relative'
          } flex flex-col z-20 w-full`}
        >
          <nav
            id="nav-bar-type-1"
            className="flex flex-row justify-center w-full z-30 bg-white"
          >
            <div className="menu-icon-toggle" onClick={this.handleClick}>
              {this.state.clicked ? (
                <FontAwesomeIcon icon={faClose} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </div>
            <div
              className={`${
                this.state.clicked ? 'primary-nav active' : 'primary-nav'
              } w-1/3`}
            >
              <ul
                id="menu-items"
                className="w-1/3 flex flex-col md:flex-row gap-5"
              >
                {MenuItems.map((item, index) => (
                  <li key={item.title}>
                    <NavLink
                      onMouseOver={() => this.handleSubMenu(item.subMenu, true)}
                      onClick={() => this.closeSubMenu()}
                      to={item.path}
                      className={item.cName}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div
              id="najjat-nav-logo"
              className="w-1/3 flex grow md:grow-0 justify-center"
            >
              <NavLink to="/" className="cursor-pointer">
                <img src={LogoBlack} alt="" width="90" />
              </NavLink>
            </div>
            <ul
              id="secondary-nav"
              className="w-1/3 flex flex-row gap-3 justify-end"
            >
              <li key="cart-icon-badge" className="mx-2 cursor-pointer">
                <NavLink to="/Cart">
                  <Cart></Cart>
                </NavLink>
              </li>
              <li>
                <BasicMenu />
              </li>
              {/*<li key="cart-icon" id="cart-icon-toggle"><FontAwesomeIcon icon={faGlobe} /></li>
                        <li key="lang-icon"id="lang-option-toggle"><a href=" ">Es/En</a></li>*/}
            </ul>
          </nav>
          <nav
            id="submenu-navbar"
            onMouseLeave={() => this.closeSubMenu()}
            className={`${
              this.state.subMenu ? 'flex' : 'hidden'
            } flex-wrap  w-full z-30`}
          >
            <div className="submenu w-1/2 md:w-1/3">
              <ul className="flex flex-col gap-5">
                {SubMenus[this.state.subMenuCategory].map((item, index) => (
                  <li key={item.info.title}>
                    <NavLink
                      onMouseOver={() => this.hoverChangeImage(item.src)}
                      onClick={() => this.closeSubMenu()}
                      className="cursor-pointer px-5 hover:underline-offset-1 hover:underline"
                      to={item.path}
                    >
                      {item.info.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="submenu w-1/2 md:w-1/3">
              <ul className="flex flex-col gap-5">
                {dataSubMenu2.map((item, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <NavLink to="/laPerez">
                    <li
                      key={item}
                      className="px-5 cursor-pointer hover:underline-offset-1 hover:underline"
                    >
                      {item}
                    </li>
                  </NavLink>
                ))}
              </ul>
            </div>
            <div className="submenu bg-blue-200 w-full md:w-1/3 overflow-hidden">
              <img
                className="w-full h-full object-cover object-top"
                src={this.state.imageSrc}
              />
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default NavBar;
