import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AdminNavbar from '@/components/NavBar/AdminNavbar';
import Sidebar from '@/components/SideBar/Sidebar';
import OrderTable from '@/components/Tables/OrdersTable';
import EnhancedTable from '@/components/Tables/StickHeader';

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState('');
  const location = useLocation();

  useLayoutEffect(() => {
    setActiveMenu(location.pathname.split('/')[2]);
  }, [location]);

  return (
    <>
      <div className="flex justify-between p-2">
        <h1>Dashboard</h1>
        <AdminNavbar />
      </div>

      <div className="flex">
        <Sidebar className="w-1/5" />
        <div className="w-4/5">
          {(() => {
            switch (activeMenu) {
              case 'products':
                return <EnhancedTable />;
              case 'orders':
                return <OrderTable />;
              default:
                return <div className="">Hola CASe</div>;
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
