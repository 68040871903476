import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import ProductsFilter from '@/components/Filters/ProductFilter';
import NavBar from '@/components/NavBar/NavBar';

const Container = styled.section``;
const MediaContainer = styled.div`
  height: auto;
`;

const ShoppingList = () => {
  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        // console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        <MediaContainer>
          <video
            src={require('../assets/img/Video/HMR 21-05-05.mp4')}
            type="video/mp4"
            ref={videoEl}
            autoPlay
            controls
            preload="auto"
            loop
            muted
            width="100%"
          />
        </MediaContainer>
      </Container>
      <Container>
        <ProductsFilter />
      </Container>
    </>
  );
};

export default ShoppingList;
