import React, { useState } from 'react';
import styled from 'styled-components';

const InputWithEdit = ({ infoValue }) => {
  const [editMenu, setEditMenu] = useState(false);

  return (
    <>
      {editMenu ? <div>Esta Activo</div> : <div>Esta Inactivo {infoValue}</div>}
    </>
  );
};

export default InputWithEdit;
