const cName = 'logo-item';

const MagazinesLogos = [
  {
    id: 1,
    title: 'Vogue',
    src: require('../../assets/img/icons/magazines/Vogue.svg').default,
    url: '#',
    cName,
  },
  {
    id: 2,
    title: 'Bazaar',
    src: require('../../assets/img/icons/magazines/Bazaar.svg').default,
    url: '#',
    cName,
  },
  {
    id: 3,
    title: 'ELLE',
    src: require('../../assets/img/icons/magazines/ELLE.svg').default,
    url: '#',
    cName,
  },
  {
    id: 4,
    title: 'Solstice',
    src: require('../../assets/img/icons/magazines/Solstice.svg').default,
    url: '#',
    cName,
  },
  {
    id: 5,
    title: 'LADYGUMM',
    src: require('../../assets/img/icons/magazines/LADYGUMM.svg').default,
    cName,
  },
  {
    id: 6,
    title: 'Grazia',
    src: require('../../assets/img/icons/magazines/Grazia.svg').default,
    url: '#',
    cName,
  },
  {
    id: 7,
    title: 'Nylon',
    src: require('../../assets/img/icons/magazines/Nylon.svg').default,
    url: '#',
    cName,
  },
];

export default MagazinesLogos;
