import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import CardProduct from '@/components/Cards/CardProductPrev';
import Accordion from '@/components/Cart/Accordion';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import { CollectionSummmer, CollectionWinter } from '@/Data/data';
import { addProduct } from '@/redux/cartRedux';
import { getProduct } from '@/services/products';

const Container = styled.div``;
const ImagesConatiner = styled.div``;
const ContentContainer = styled.div``;
const DetailsContainer = styled.div`
  position: sticky;
  top: 20%;
  left: 53%;
`;

const QuantityForm = styled.form`
  display: flex;
  align-items: center;
  width: auto;
  margin: 0 auto;
  text-align: center;

  & #decrease {
    margin-right: -4px;
    border-radius: 8px 0 0 8px;
  }

  & #increase {
    margin-left: -4px;
    border-radius: 0 8px 8px 0;
  }

  & #input-wrap {
    margin: 0px;
    padding: 0px;
  }

  & #number {
    text-align: center;
    border: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0px;
    width: 40px;
    height: 25px;
  }

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const QuantityButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 25px;
  text-align: center;
  vertical-align: middle;
  padding: 11px 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

const ContainerPreviewProducts = styled.section``;

const ProductDetail = () => {
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const getProductById = async () => {
        const response = await getProduct(id);
        const data = response.data;
        setProduct(data);
        setColor(data.color[0]);
        setSize(data.size[0]);
      };

      getProductById();
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const handleAddProduct = () => {
    dispatch(addProduct({ ...product, quantity, color, size }));
  };

  return (
    <>
      <NavBar />
      <Container className="flex flex-wrap bg-yellow-nj">
        <ImagesConatiner className="w-1/2 flex flex-col gap-1">
          <img src={product.gallery} alt={product.sku} />

          {/*{CollectionWinter.slice(0, 4).map((item, index) => {
            return <img src={item.src} alt="" className="w-full" />;
          })}*/}
        </ImagesConatiner>
        <ContentContainer className="relative w-1/2">
          <DetailsContainer className="bg-yellow-nj w-11/12 flex flex-col gap-2">
            <div className="title">
              <h1>{product.title}</h1>
            </div>
            <div className="colores flex flex-row justify-between">
              <div>
                <h2>Colores</h2>
              </div>
              <div>Azul</div>
            </div>
            <div className="tallas flex flex-row justify-between">
              <div>
                <h2>Tallas</h2>
              </div>
              <select>
                <option>Chica</option>
                <option>Mediana</option>
                <option>Grande</option>
              </select>
            </div>
            <div className="quanitity flex flex-row justify-between">
              <h2>Cantidad</h2>
              <QuantityForm>
                <QuantityButton
                  id="decrease"
                  value="Decrease Value"
                  onClick={() =>
                    quantity > 0 ? setQuantity(quantity - 1) : ''
                  }
                >
                  -
                </QuantityButton>
                <input type="number" id="number" value={quantity} />
                <QuantityButton
                  id="increase"
                  value="Increase Value"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  +
                </QuantityButton>
              </QuantityForm>
            </div>
            <div className="colores flex flex-row justify-between">
              <div>
                <h2>Precio</h2>
              </div>
              <div>
                <h1>${product.price}</h1>
              </div>
            </div>

            <button className="button-23" onClick={handleAddProduct}>
              Agregar a Carrito
            </button>
            <Accordion></Accordion>
          </DetailsContainer>
        </ContentContainer>
      </Container>

      <ContainerPreviewProducts className="flex flex-wrap justify-center gap-10 py-5 md:py-20">
        {CollectionWinter.slice(0, 4).map((item, index) => (
          <CardProduct
            item={item}
            key={index}
            cName=" w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/5"
          ></CardProduct>
        ))}
      </ContainerPreviewProducts>
      <Newsletter></Newsletter>
    </>
  );
};

export default ProductDetail;
