import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import Footer from '@/components/Footer/Footer';
import AdminLoginPage from '@/pages/AdminLoginPage';
import Cart from '@/pages/Cart';
import CollectionPage from '@/pages/CollectionPage';
import Dashboard from '@/pages/Dashboard';
import DashboardProductDetails from '@/pages/DashboardProductDetails';
import FrontPage from '@/pages/FrontPage';
import FrontPage2 from '@/pages/FrontPage2';
import LaPerezIndex from '@/pages/LaPerezPage';
import LoginPage from '@/pages/LoginPage';
import Nosotros from '@/pages/NosotrosPage';
import ProductDetail from '@/pages/ProductDetail';
import ProductList from '@/pages/ProductsList';
import RegisterPage from '@/pages/RegisterPage';

function App() {
  const user = useSelector(state => state.user.currentUser);

  const login = user ? true : false;

  let adminLogin = false;

  if (user != null && user.isAdmin === true) {
    adminLogin = true;
  }

  return (
    <>
      <Routes>
        <Route
          path="/admin/"
          element={adminLogin ? <Dashboard /> : <AdminLoginPage />}
        ></Route>
        <Route
          path="/admin/products/:id"
          element={<DashboardProductDetails />}
        />
        <Route
          path="/admin/:id"
          element={adminLogin ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route path="/" element={<FrontPage />} />
        <Route
          path="/register"
          element={login ? <Navigate to="/" /> : <RegisterPage />}
        />
        <Route
          path="/login"
          element={login ? <Navigate to="/" /> : <LoginPage />}
        />
        <Route path="/frontPage" element={<FrontPage />} />
        <Route path="/laPerez" element={<LaPerezIndex />} />
        <Route path="/Nosotros" element={<Nosotros />} />
        <Route path="/Women" element={<FrontPage2 />} />
        <Route path="/Men" element={<FrontPage />} />
        <Route exact path="/ProductList/:id" element={<ProductList />} />
        <Route exact path="/ProductList/:id/:id" element={<ProductDetail />} />
        <Route path="/Collections" element={<CollectionPage />} />
        <Route path="/Cart" element={<Cart />} />
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
