import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from '@/services/user';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    'backgroundColor': '#44b700',
    'color': '#44b700',
    'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const ArrowIcon = styled(ArrowDropDownCircleIcon)(({ theme }) => ({
  color: '#44b700',
}));

const AdminNavbar = () => {
  const user = useSelector(state => state.user.currentUser);

  const handleLogout = e => {
    console.log(e);
    logout();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="flex flex-end">
        <div className="user-toolbar flex gap-5 justify-center items-center">
          <StyledBadge
            className=""
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <Avatar alt={user.username} src="/static/images/avatar/1.jpg" />
          </StyledBadge>
          <div className="user-details flex gap-2 justify-center items-center">
            <h2>{user.username}</h2>
            <div>
              <ArrowDropDownCircleIcon
                fontSize="small"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className=""
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleLogout}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Link to="/register">
                  <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                </Link>
                <Link to="/login">
                  <MenuItem onClick={handleClose}>Cambiar Contraseña</MenuItem>
                </Link>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNavbar;
