const cName = 'img00-item';

export const CollectionSummmer = [
  {
    id: 'Sum-N&X-01',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: 'https://picsum.photos/750/570?random=1',
    cName,
  },
  {
    id: 'Sum-N&X-02',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: 'https://picsum.photos/750/570?random=2',
    cName,
  },
  {
    id: 'Sum-N&X-03',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: 'https://picsum.photos/750/570?random=3',
    cName,
  },
  {
    id: 'Sum-N&X-04',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: 'https://picsum.photos/750/570?random=4',
    cName,
  },
];

export const CollectionWinter = [
  {
    id: 'Wnt-N&X-01',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_05.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-02',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-03',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_22.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-04',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-08',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-09',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_22.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-10',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-05',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-06',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_22.jpg'),
    cName,
  },
  {
    id: 'Wnt-N&X-07',
    info: {
      title: 'NajjatHarb_by_Xavier',
      desc: 'Vestido Negro con olanes especiales ',
    },
    price: 100,
    src: require('../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg'),
    cName,
  },
];
