import axios from 'axios';

async function postPaymentIntents(order) {
  try {
    const paymentStatus = await axios.post('http://localhost:3001/api/checkout', {
      id: order.id,
      amount: order.price * 100,
    });

    return paymentStatus;
  } catch (err) {
    // console.log(err);
  }

  return false;
}

export { postPaymentIntents };
