import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getProductList } from '@/services/products';

import CardProduct from './Cards/CardProd';

const Container = styled.div``;

const Products = ({ cat, useFilters, sort }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    try {
      const getProducts = async () => {
        const response = await getProductList(cat);
        console.log(response);
        const data = response.data;
        setProducts(data);
      };

      getProducts();
    } catch (err) {
      console.log(err);
    }
  }, [cat]);

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter(item =>
          Object.entries(useFilters).every(([key, value]) =>
            item[key].includes(value),
          ),
        ),
      );

    console.log(filteredProducts);
  }, [products, cat, useFilters]);

  useEffect(() => {
    if (sort === 'newest') {
      setFilteredProducts(prev =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt),
      );
    } else if (sort === 'asc') {
      setFilteredProducts(prev => [...prev].sort((a, b) => a.price - b.price));
    } else {
      setFilteredProducts(prev => [...prev].sort((a, b) => b.price - a.price));
    }
  }, [sort]);

  return (
    <>
      <Container className="flex flex-wrap justify-center gap-5 py-5 md:py-10">
        {cat
          ? filteredProducts.map((item, index) => (
              <>
                <CardProduct
                  name={item.title}
                  imgSrc={item.gallery}
                  price={item.price}
                  itemId={item._id}
                  cName="w-3/4 sm:w-2/5 md:w-[30%]"
                ></CardProduct>
              </>
            ))
          : products.map((item, index) => (
              <>
                <CardProduct
                  name={item.title}
                  imgSrc={item.gallery}
                  price={item.price}
                  itemId={item._id}
                  cName="w-3/4 sm:w-1/2 md:w-[30%]"
                ></CardProduct>
              </>
            ))}
      </Container>

      <Container></Container>
    </>
  );
};

export default Products;
