import 'swiper/css/bundle';

import React from 'react';
import styled from 'styled-components';

import AnimateLogo from '@/components/AnimateLogo.jsx';
import { LogoP1 } from '@/components/AnimationSVG/LogoP1';
import GridSlider from '@/components/Carousel/GridSlider';
import HistorySlider from '@/components/Carousel/HistorySlider';
import NajjatSlider from '@/components/Carousel/NajjatSlider';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import VerticalCarousel from '@/components/VerticalCarousel';
import { CollectionWinter } from '@/Data/data';
import { Magazines } from '@/Data/magazines';

const Container = styled.section`
  position: relative;
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
`;
const FirmContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 25%;
  height: 70vh;
  width: 60vw;
  @media (max-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
    height: 50%;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 4rem;
`;

const SubTitle = styled.h3`
  text-align: left;
  font-size: 1.2rem;
`;

const AuthorContainer = styled.div`
  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
    line-height: 1rem;
    padding-left: 10rem;
  }
`;

const Nosotros = () => (
  <React.Fragment>
    <NavBar />
    <Container className="flex flex-wrap bg-black">
      <ImageContainer className=" h-full md:w-2/5">
        <Image src={require('../assets/img/Artista/art-01.png')} />
      </ImageContainer>
      <FirmContainer>
        <LogoP1 className="w-full" />
      </FirmContainer>
    </Container>
    <Container>
      <NajjatSlider />
    </Container>
    <Container className="py-32 bg-yellow-50 flex flex-col justify-start">
      <div className="title-article flex flex-col justify-center gap-4 pl-64">
        <SubTitle>Primavera 2022 Pre-colección</SubTitle>
        <SubTitle>Para ella </SubTitle>
      </div>
      <VerticalCarousel photos={CollectionWinter}></VerticalCarousel>
      <AuthorContainer id="artist-logo-nx" className="first pl-64">
        <Title>Najjat Harb</Title>
        <SubTitle>& Xavier Berg</SubTitle>
      </AuthorContainer>
    </Container>
    <Container>
      <HistorySlider />
    </Container>
    <Container className="magazine-slider overflow-none">
      <Title>Revistas y Reconocimientos</Title>
      <GridSlider Magazines={Magazines}></GridSlider>
    </Container>
    <Newsletter></Newsletter>
  </React.Fragment>
);

export default Nosotros;
