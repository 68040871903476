import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as importedLine } from '@/assets/img/icons/Logo.svg';

const Ornament = ({ color = '#ccc' }) => {
  const [pathLength, setPathLength] = useState(0);
  useEffect(() => {
    const path = document.getElementById('ornamentPath');
    const length = path.getTotalLength();
    setPathLength(length / 2);
  }, []);
  return <Line pathLength={pathLength} color={color} />;
};

const ornamentPath = (length, color) => keyframes`
0%{
  fill-opacity:0;
  stroke-opacity:1;
  fill:transparent;
  stroke-dashoffset:${length}
}
95% {
  stroke-opacity:1;
  fill-opacity:0.85;
  fill:${color};
  stroke-dashoffset:0;
}
100%{
  stroke-opacity:1;
  fill-opacity:1;
  fill:${color};
  stroke-dashoffset:0;
}
`;

const Line = styled(importedLine)`
  height: 90%;
  display: block;
  margin: auto;
  transform: rotateX(180deg);
  & #ornamentPath {
    stroke: ${props => props.color};
    fill: ${props => props.color};
    stroke-dasharray: ${props => props.pathLength};
    stroke-dashoffset: ${props => props.pathLength};
    stroke-opacity: 0;
    fill-opacity: 0;
    animation: ${props => ornamentPath(props.pathLength)} 7s ease-in;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
`;

export default Ornament;
