import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  height: 305px;
  width: 245px;

  ${props => {
    switch (props.mode) {
      case 'invert':
        return css`
          box-shadow: -45px 30px 0px -10px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: -45px 30px 0px -10px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: -45px 30px 0px -10px rgba(0, 0, 0, 0.75);
        `;
      default:
        return css`
          box-shadow: 45px -30px 0px -10px rgba(0, 0, 0, 0.75);
          -webkit-box-shadow: 45px -30px 0px -10px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 45px -30px 0px -10px rgba(0, 0, 0, 0.75);
        `;
    }
  }}
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const MagazineCard = ({ item, mode }) => (
  <React.Fragment>
    <Container mode={mode}>
      <Image src={item.src}></Image>
    </Container>
  </React.Fragment>
);

export default MagazineCard;
