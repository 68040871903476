import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import ProductDetail from '@/components/DashboardSubPages/ProductDetail';

const DashboardProductDetails = () => {
  const location = useLocation();
  return (
    <div>
      <ProductDetail></ProductDetail>
    </div>
  );
};

export default DashboardProductDetails;
