import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { mobile } from '@/assets/js/responsive';

const Wrapper = styled.div``;
const Slider = styled.div`
  display: flex;
  height: ${props => (props.type === 'sec-slide' ? 350 : 300)}px;
  overflow-x: overlay;
  scrollbar-width: none; // for firefox
  -ms-overflow-style: none; //for Edge
  &::-webkit-scrollbar {
    display: none;
  }
  border-bottom: 1px solid black;
  ${mobile({
    height: '190px',
  })}
`;
const Item = styled.div`
  min-width: ${props => (props.type === 'sec' ? 436 : 400)}px;
  height: 100%;
  display: flex;
  border-left: 1px solid black;
  position: ${props => (props.type === 'sec' ? 'relative' : 'none')};
  ${mobile({ minWidth: '228px' })}
`;
const ItemTitle = styled.div`
  width: 100px;
  height: 100%;
  position: relative;
  overflow: hidden;
`;
const Title = styled.h3`
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: bottom left;
  left: 100%;
  bottom: 0px;
  padding: 5px;
  width: 300px;
  color: black;
  font-size: 23px;
  font-weight: bold;
`;
const Image = styled.img`
  width: 300px;
  height: 100%;
`;
const Para = styled.p`
  font-size: 70px;
  margin: 30px;
  width: 100%;
  font-weight: bold;
  line-height: 0.8;
  letter-spacing: -2px;
  ${mobile({ fontSize: '32px', margin: '5px' })}
`;
const SecTitle = styled.h4`
  font-size: 22px;
  margin: 30px 0 0 30px;
  ${mobile({
    fontSize: '16px',
    margin: '5px 0 0 5px',
  })}
`;
const SecImage = styled.img`
  max-width: 100%;
  border: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 363px;
  height: 262px;
  ${mobile({
    width: '196px',
    height: '136px',
  })}
`;

const SecImageContainer = styled.div`
  position: absolute;
  width: 363px;
  height: 262px;
  right: 0;
  bottom: 0;
  overflow: hidden;

  ${mobile({
    width: '196px',
    height: '136px',
  })}

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const Slide = () => (
  <>
    <Link to="/ProductList/Vestidos">
      <Wrapper>
        <Slider type="sec-slide" style={{ borderTop: '1px solid black' }}>
          <Item
            type="sec"
            style={{ backgroundColor: '#f5f4f2', cursor: 'e-resize' }}
          >
            <Para>NUESTRAS MARCAS</Para>
          </Item>
          <Item type="sec">
            <SecTitle>LA PEREZ</SecTitle>
            <SecImageContainer>
              <SecImage
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0225.jpg')}
                alt="Patou"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>NAJJAT</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_24.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>RAICES</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0616.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>READY TO WEAR</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-1.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>NAJJAT</SecTitle>
            <SecImageContainer>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/najjatharb-5a167.appspot.com/o/Summer2022%2FNJP%2FNajjatHarb_by_XavierBergman%2005.jpg?alt=media&token=f40e3dfa-bb09-4bc4-9111-58b2e16569c3"
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>VACIOS</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0757.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>VESTIDOS 2022</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0717.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>CAMISAS</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-5.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>MIS RAICES</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-6.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
        </Slider>
        <Slider type="sec-slide">
          <Item type="sec">
            <SecTitle>MIS RAICES</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0383.jpg')}
                alt="GUCCI"
                className="object-center"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>NAJJAT-XAVIER</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_31.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item
            type="sec"
            style={{ backgroundColor: '#f5f4f2', cursor: 'e-resize' }}
          >
            <Para>READY TO-WEAR</Para>
          </Item>
          <Item type="sec">
            <SecTitle>OFF-WHITE </SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0691.jpg')}
                alt="GUCCI"
                className="object-center"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>SUMMER 2021</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0107.jpg')}
                alt="GUCCI"
                className="object-center"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>MEXICO 2021</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_28.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>ZAPATOS NAJJAT</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/Zapatos/zap-nj-1.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
        </Slider>
        <Slider type="sec-slide">
          <Item type="sec">
            <SecTitle>VACIOS</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0273.jpg')}
                alt="GUCCI"
                className="object-contain"
              />
            </SecImageContainer>
          </Item>
          <Item
            type="sec"
            style={{ backgroundColor: '#f5f4f2', cursor: 'e-resize' }}
          >
            <Para>LO MÁS HOT</Para>
          </Item>
          <Item type="sec">
            <SecTitle>PEREZ 2022</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0626.jpg')}
                alt="GUCCI"
                className="object-center"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>VESTIDOS NAJJAT</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/NJP/NajjatHarb_by_XavierBergman_20.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>MIS RAICES</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-5.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>NAJJAT XAVIER</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/LaPerez/5L3A0442.jpg')}
                alt="GUCCI"
                className="object-contain"
              />
            </SecImageContainer>
          </Item>
          <Item type="sec">
            <SecTitle>HOMBRE RAICES</SecTitle>
            <SecImageContainer>
              <img
                src={require('../../assets/img/Summer2022/Mens/Mis_Raices-Men-3.jpg')}
                alt="GUCCI"
              />
            </SecImageContainer>
          </Item>
        </Slider>
      </Wrapper>
    </Link>
  </>
);

export default Slide;
