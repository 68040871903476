// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import required modules
// import Swiper core and required modules
import { A11y, EffectFade, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import LaPerezIndex from '@/pages/LaPerezPage';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const CarouselBox = styled(Swiper)`
  width: 100%;
  display: inline-grid;

  & .swiper-button-next,
  & .swiper-button-prev {
    color: black;
  }

  @media (max-width: 600px) {
    height: fit-content;
  }
`;

const Slider = styled.div`
  position: relative;
  height: 650px;
  width: 100vw;
  overflow: hidden;
  top: 0;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  height: 600px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    height: 450px;
  }
`;

const Image = styled.img`
  object-fit: contain;
  height: 90%;
  transition: all 1.5s ease-in;
  opacity: 0;

  &.active {
    opacity: 1;
  }
`;
const ContentContainer = styled.div`
  position: relative;

  @media (max-width: 600px) {
    height: fit-content;
  }
`;

const Description = styled.p`
  position: absolute;
  text-align: justify;
  font-family: 'Didot';
  font-style: italic;
  font-size: 1.5rem;
  color: black;
  transition: all 1s ease-in;
  overflow: hidden;
  width: ${props => props.txtwidth || '600px'};
  opacity: 0;
  left: 100%;
  &.active {
    opacity: 1;
    left: ${props => props.left || '0%'};
  }

  @media (max-width: 600px) {
    position: relative;
    width: 95%;
    height: fit-content;
    &.active {
      left: 0%;
    }
  }
`;

const NajjatSlider = () => (
  <>
    <CarouselBox
      // install Swiper modules
      modules={[Navigation, Pagination, A11y, EffectFade]}
      spaceBetween={50}
      slidesPerView={1}
      effect={'fade'}
      navigation
      pagination={{
        clickable: true,
        type: 'fraction',
      }}
      onSwiper={swiper => {}}
      onSlideChange={() => {}}
    >
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-1" className="flex flex-wrap p-5 md:p-20">
            <ImageContainer className="w-full md:w-2/5">
              <Image
                className={`${isActive ? 'active' : 'inactive'}`}
                src={require('../../assets/img/Artista/sketch/sketch-1.png')}
                alt="Image-1"
              />
            </ImageContainer>
            <ContentContainer className="w-full md:w-3/5 flex justify-center items-center">
              <Description
                className={`${isActive ? 'active' : 'inactive'} px-10`}
              >
                “Hoy, cualquiera puede vestir chic a base de prendas baratas.
                Hay diseños elegantes en cualquier nivel económico. Puedes ser
                la persona más estilosa del mundo tan solo con una camiseta y
                unos vaqueros, el factor diferencial eres tú.”
              </Description>
            </ContentContainer>
          </Slider>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-2" className="flex flex-wrap p-5 md:p-20">
            <ContentContainer className="w-full md:w-1/2 flex justify-center items-center">
              <Description
                className={`${isActive ? 'active' : 'inactive'} px-10`}
              >
                “Hoy, cualquiera puede vestir chic a base de prendas baratas.
                Hay diseños elegantes en cualquier nivel económico. Puedes ser
                la persona más estilosa del mundo tan solo con una camiseta y
                unos vaqueros, el factor diferencial eres tú.”
              </Description>
            </ContentContainer>
            <ImageContainer className="flex flex-col w-full md:w-1/2">
              <Image
                className={`${isActive ? 'active' : 'inactive'}  object-top`}
                src={require('../../assets/img/Artista/sketch/sketch-2.png')}
                alt="Image-1"
              />
            </ImageContainer>
          </Slider>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {({ isActive }) => (
          <Slider id="slide-3" className="flex flex-wrap p-5 md:p-20">
            <ImageContainer className="w-full md:w-2/5">
              <Image
                className={`${isActive ? 'active' : 'inactive'}`}
                src={require('../../assets/img/Artista/sketch/sketch-3.png')}
                alt="Image-1"
              />
            </ImageContainer>
            <ContentContainer className="w-full md:w-3/5 flex justify-center items-center">
              <Description
                className={`${isActive ? 'active' : 'inactive'} px-10`}
              >
                “Hoy, cualquiera puede vestir chic a base de prendas baratas.
                Hay diseños elegantes en cualquier nivel económico. Puedes ser
                la persona más estilosa del mundo tan solo con una camiseta y
                unos vaqueros, el factor diferencial eres tú.”
              </Description>
            </ContentContainer>
          </Slider>
        )}
      </SwiperSlide>
    </CarouselBox>
  </>
);

export default NajjatSlider;
