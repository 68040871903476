import axios from 'axios';

async function getOrderList(accessToken) {
  return await axios.get('http://localhost:3001/api/order/', {
    headers: {
      token: `Bearer ${accessToken}`,
    },
  });
}

export { getOrderList };
