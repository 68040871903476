import axios from 'axios';

import { publicRequest } from './requestMethods';

async function getProductList(cat) {
  return await axios.get(
    cat ? `http://localhost:3001/api/products?category=${cat}` : 'http://localhost:3001/api/products',
  );
}

async function getProduct(id) {
  return await publicRequest.get(`products/find/product/${id}`);
}

async function getColors(cat) {
  return await axios.get(
    cat ? `http://localhost:3001/api/products/getColors?category=${cat}` : 'http://localhost:3001/api/products',
  );
}

async function getSizes(cat) {
  return await axios.get(
    cat ? `http://localhost:3001/api/products/getSizes?category=${cat}` : 'http://localhost:3001/api/products',
  );
}

export { getProductList, getProduct, getColors, getSizes };
