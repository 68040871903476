// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
// import required modules
// import Swiper core and required modules
import { Autoplay, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import MagazineCard from '../Cards/MagazineCard';

const CarouselBox = styled(Swiper)`
  width: 100%;
  height: auto;
  display: inline-grid;
  padding: 5rem;

  & .swiper-pagination.swiper-pagination-progressbar {
    top: inherit;
    bottom: 0;
  }

  & .swiper-pagination-progressbar-fill {
    background: black;
  }
`;

const MagazineContainer = styled.div`
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

const GridSlider = ({ Magazines }) => {
  const getMagazineContent = Magazines => {
    const content = [];

    for (let i = 0; i < Magazines.length - 1; i++) {
      if (i % 2 === 0) {
        content.push(
          <SwiperSlide className="flex-flex-col mr-[45px]">
            <MagazineContainer>
              <MagazineCard item={Magazines[i]} key={i} />
            </MagazineContainer>
            <MagazineContainer className="">
              <MagazineCard item={Magazines[i + 1]} key={i + 1} />
            </MagazineContainer>
          </SwiperSlide>,
        );
      } else {
        content.push(
          <SwiperSlide className="flex-flex-col gap-y-8 mr-[45px]">
            <MagazineContainer>
              <MagazineCard
                item={Magazines[i]}
                key={Magazines[i].info.title}
                mode="invert"
              />
            </MagazineContainer>
            <MagazineContainer className="">
              <MagazineCard
                item={Magazines[i + 1]}
                key={Magazines[i + 1].info.title}
                mode="invert"
              />
            </MagazineContainer>
          </SwiperSlide>,
        );
      }

      i++;
    }

    return content;
  };

  return (
    <>
      <CarouselBox
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 2,
          },
        }}
        slidesPerView={1}
        spaceBetween={10}
        speed={8000}
        loop={true}
        autoplay={{
          delay: 500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'progressbar',
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Scrollbar]}
      >
        {getMagazineContent(Magazines)}
      </CarouselBox>
    </>
  );
};

export default GridSlider;
