const cName = 'logo-item';

const SocialLogos = [
  {
    id: 1,
    title: 'Facebook',
    src: require('../../assets/img/icons/social/facebook_icn.svg').default,
    url: '#',
    cName,
  },
  {
    id: 2,
    title: 'Instagram',
    src: require('../../assets/img/icons/social/instagram_icn.svg').default,
    url: '#',
    cName,
  },
];

export default SocialLogos;
